<template>
  <div class="invalid-feedback">
    <ul>
      <li v-for="err in errors" class="text-danger">{{ field }}: {{ err }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['field', 'errors']
}
</script>
