import WaveSurfer from 'wavesurfer.js/dist/wavesurfer.js';
//import WaveSurfer from '/Users/ben/src/wavesurfer.js/src/wavesurfer.js';
import RegionsPlugin from 'waveforms/regions';
//import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
//import RegionsPlugin from '/Users/ben/src/wavesurfer.js/src/plugin/regions.js';
import Rails from '@rails/ujs';
import fetch_csrf from 'utils/csrf';

import setupSharing from 'mix_player/sharing.js';
import Volume from 'mix_player/volume.js';
import MixAlign from 'mix_player/align.js';
import timeFormat from 'utils/time_format'

class MixPlayer {
  constructor(mixCollection, div) {
    const jqDiv = $(div);
    this.mixCollection = mixCollection;
    this.element = div;
    this.mixID = jqDiv.data("mix-id");
    this.songID = jqDiv.data("song-id");
    this.audioSrc = jqDiv.data("audioSrc");
    this.title = jqDiv.data("mix-title");
    this.initialVolume = jqDiv.data("initial-volume") || 0.0;
    this.forcedOpen = false;
    this.controls = jqDiv.find('.play-pause');

    jqDiv.data("mix-player", this);

    setupSharing(jqDiv.find(".share-link"), '/share/mix/' + this.mixID + '.json', jqDiv.data("mix-title"), "mix");

    this.wave = jqDiv.find("wave");

    this.controls.click(ev => {
      ev.preventDefault()

      let target = $(ev.target).closest("svg")

      if ( target.hasClass("disabled") || !target.hasClass("mix-play-icon")) {
        return
      }

      if ( target.hasClass("ab-text") ) {
        this.initIfNeeded(() => this.mixCollection.abClick(this))
      }else {
        this.initIfNeeded(() => this.mixCollection.playPauseClick(this))
      }
    })

    jqDiv.find(".expand-link").click(e => {
      e.preventDefault()

      if ( jqDiv.hasClass("opened") )
        this.close()
      else
        this.open()
    })

    // this.wireTopAndBottom(this.wave);

    if (jqDiv.hasClass("opened")) {
      this.initWaveSurfer()
      this.loadMetadata()
    }
  }

  initWaveSurfer() {
    if ( this.ws )
      return

    let backend = 'MediaElementWebAudio'

    // hopefully revert after https://bugs.webkit.org/show_bug.cgi?id=203435 lands
    if ( this.isiOSSafari() ) {
      backend = 'MediaElement'
    }

    this.ws = WaveSurfer.create({
      container: $(this.element).find(".waveform")[0],
      waveColor: '#cccccc',
      progressColor: '#ff8e3e',
      barWidth: 2,
      barMinHeight: 1,
      height: 100,
      backend: backend,
      plugins: [ RegionsPlugin.create({maxRegions: 1}) ],
    });

    if ( !this.isiOSSafari() ) {
      this.ws.backend.setVolume = function(value) {
        this.gainNode.gain.setValueAtTime(value, this.ac.currentTime);
      }

      this.ws.backend.getVolume = function() {
        return this.gainNode.gain.value
      }
    }

    this.ws.on('region-created', (region) => this.regionCreated(region));
    this.ws.on('region-updated', (region) => this.mixCollection.regionUpdated(region));

    this.ws.on('region-removed', (region) => {
      this.mixCollection.regionRemoved(region)
      this.ws.setPlayEnd(this.ws.getDuration());
    });

    this.ws.on('play', () => {
      if ( this.pauseEvents )
        return

      if ( this.lastTime != null && this.lastTime > 0 )
        this.mixCollection.resetStates();
    });

    this.ws.on('pause', () => {
      if ( this.pauseEvents )
        return

      this.mixCollection.resetStates();
    });

    this.ws.on('finish', () => {
      this.mixCollection.onFinish(this);
    })

    let seekTime = [$(this.element).find(".seek-time")[0], $(".global-seek-time")[0]]
    this.lastTime = null

    this.ws.on('audioprocess', (time) => {
      if ( this.pauseEvents )
        return

      if ( time > 0 && this.spinnerActive )
        this.mixCollection.resetStates()

      time = Math.round(time)

      if ( time == this.lastTime )
        return


      this.lastTime = time

      seekTime.forEach(el => el.innerHTML = timeFormat(time))
    });

    this.volume = new Volume(
      $(this.element).find('.volume-slider'),
      $(this.element).find('.volume-text'),
      this.ws,
      this.mixID
    );
  }

  initIfNeeded(fn) {
    if ( !this.metadataLoaded ) {
      this.setSpinner()

      this.initWaveSurfer()
      if ( this.isiOSSafari() )
        this.initIOS(this.element, fn)
      else
        this.loadMetadata().then(fn)
    } else {
      this.setSpinner()
      fn()
    }
  }

  back15() {
    let cur = this.getCurrentTime()
    this.seekTo(Math.max(0, cur - 15))
  }

  forward15() {
    this.seekTo(this.getCurrentTime() + 15)
  }

  initIOS(container, fn) {
    if ( !this.metadataLoaded ) {
      this.media = document.createElement("audio");
      this.media.controls = false;
      this.media.autoplay = false;
      this.media.preload = 'none';
      this.media.src = this.audioSrc;
      container.appendChild(this.media);
      this.ws.loadMediaElement(this.media, [1])
      this.loadMetadata()
    }

    fn()
  }

  isiOSSafari() {
    var ua = window.navigator.userAgent;
    var iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
    var webkit = ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    return iOSSafari
  }

  loadMetadata() {
    if ( this.metadataLoaded ) return

    return this.fetchMetadata().then(() => this.metadataLoaded = true)
  }

  fetchMetadata() {
    return fetch("/mixes/" + this.mixID + "/metadata")
      .then(response => {
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        }
        return response.json();
      })
      .then(md => {
        // load peaks into wavesurfer.js
        if ( this.isiOSSafari() ) {
          if ( !this.media ) {
            this.ws.load(this.audioSrc, md.waveform, 'none', md.duration)
          } else {
            this.ws.backend.setPeaks(md.waveform, md.duration)
            this.ws.drawBuffer()
          }
        } else {
          this.ws.load(this.audioSrc, md.waveform, 'none', md.duration);

          this.ws.backend.media.crossOrigin = "use-credentials";

          this.volume.initVolume(parseFloat(this.initialVolume));

          if (md.volume_offset)
            this.volume.initVolumeOffset(md.volume_offset);
        }
      })
  }

  region() {
    return Object.values(this.ws.regions.list)[0];
  }

  isLinked(a, b) {
    return a && a.data && a.data.linked &&
        b && b.data && b.data.linked;
  }


  addLinkedRegion(props) {
    props['data'] = { linked: true };
    this.ws.addRegion(props);
  }

  otherRegionUpdated(other) {
    if ( this.isLinked(other, this.region()) ) {
      this.region().update({start: other.start, end: other.end});
    }
  }

  otherRegionRemoved(other) {
    if ( this.isLinked(other, this.region()) )
      this.region().remove();
  }

  regionCreated(region) {
    this.mixCollection.loopRegion(this, region);
    this.addLoopIcons(region);
    this.mixCollection.resetStates();
    region.on('out', () => region.play());
  }

  addLoopIcons(region) {
    const linkIcon = $('#link-icon-tmpl').clone();
    const xIcon = $('#x-icon-tmpl').clone();

    xIcon.click((e) => {
      e.preventDefault();
      e.stopPropagation();
      region.remove();
    });
    xIcon.show();

    if ( region.data && region.data.linked )
      linkIcon.addClass("linked");

    linkIcon.click((e) => {
      if ( region.data.linked ) {
        linkIcon.removeClass("linked");
        region.data.linked = false;
      } else {
        const linked = this.mixCollection.firstLinked();
        if ( linked )
          region.update({start: linked.region().start, end: linked.region().end});

        linkIcon.addClass("linked");
        region.data.linked = true;
      }
      e.preventDefault();
      e.stopPropagation();
    });

    $(region.element).append(linkIcon);
    $(region.element).append(xIcon);
  }

  wireTopAndBottom(jqDiv) {
    this._cursorStatus = null;

    jqDiv.mousemove( (ev) => {
      const y = ev.pageY - jqDiv.offset().top;
      if ( y > 40 && this._cursorStatus != "bottom" ) {
        this._cursorStatus = "bottom";
        this.ws.disableDragSelection();
        jqDiv.css("cursor", "pointer");
      } else if ( y <= 40 && this._cursorStatus != "top" ) {
        this._cursorStatus = "top";
        this.ws.enableDragSelection({});
        jqDiv.css("cursor", "text");
      }
    });
  }

  hideLoop() {
    this.controls.find('.play-loop-icon').hide();
  }

  isOpen() {
    return $(this.element).hasClass("opened")
  }

  close(auto) {
    if ( !this.isOpen() )
      return

    if ( this.forcedOpen && auto )
      return

    let el = $(this.element)
    let height = el.find(".player-body-wrapper").outerHeight()
    el.find(".player-body").height(0)

    el.removeClass("opened")
    el.addClass("collapsed")

    this.forcedOpen = false
  }

  open(auto) {
    if ( this.isOpen() )
      return

    if ( !auto )
      this.forcedOpen = true

    this.initWaveSurfer()

    let el = $(this.element)
    let height = el.find(".player-body-wrapper").outerHeight()

    let pBody = el.find(".player-body")

    if ( this.isiOSSafari() ) {
        pBody.height("auto")
    } else {
      pBody.one("transitionend", () => {
        pBody.height("auto")
      })

      pBody.height(height)
    }

    el.removeClass("collapsed")
    el.addClass("opened")

    this.loadMetadata()
  }

  setSpinner() {
    this.spinnerActive = true
    this.controls.find('.play-icon').hide()
    this.controls.find('.pause-icon').hide()

    this.controls.find('.spinner').show()
    this.controls.find('.spinner animateTransform').each((i, el) => el.beginElement())
  }

  hideSpinner() {
    if ( !this.spinnerActive )
      return


    this.spinnerActive = false
    this.controls.find('.spinner').hide();
    setTimeout(() =>
      this.controls.find('.spinner animateTransform').each((i, el) => el.endElement()),
      500
    )
  }

  isPlaying() {
    return this.ws.isPlaying();
  }

  play(time, prevRegion) {
    const r = this.region();
    const current = this.getCurrentTime();

    if ( r ) {
      if ( this.isLinked(prevRegion, r) && r.start <= time && time <= r.end )
        r.play(time);
      else if ( r.start <= current && current <= r.end )
        r.play(current);
      else
        r.play();
    } else {
      return this.ws.play(time);
    }
  }

  getCurrentTime() {
    return this.ws.getCurrentTime();
  }

  pause() {
    return this.ws.pause();
  }

  seekTo(time) {
    this.ws.setCurrentTime(time)
  }

  playPause() {
    if ( this.isPlaying() )
      this.pause()
    else
      this.play()
  }
}

class MixCollection {
  constructor() {
    this.players = [];
    this.activePlayer = null;
    this.inUpdateLoop = false;

    $(".player").each((i, div) => {
      const player = new MixPlayer(this, div);
      player.index = i;
      this.players.push(player);
    });

    $(".player-global-controls").click((e) => this.onGlobalControlClick(e))
  }

  onGlobalControlClick(ev) {
    ev.preventDefault()

    let target = $(ev.target).closest("svg")

    if ( target.hasClass("disabled") || !target.hasClass("mix-play-icon")) {
      return
    }

    if ( target.hasClass("pause-icon") ) {
      this.lastActivePlayer = this.activePlayer
      this.activePlayer.pause()
      this.activePlayer = null
    } else if ( target.hasClass("play-icon") ) {
      if ( this.lastActivePlayer ) {
        this.lastActivePlayer.play()
        this.activePlayer = this.lastActivePlayer
      }
    } else if ( target.hasClass("back-15") ) {
      this.activePlayer.back15()
    } else if ( target.hasClass("forward-15") ) {
      this.activePlayer.forward15()
    } else if ( target.hasClass("back") ) {
      if ( this.activePlayer.getCurrentTime() > 2 || this.activePlayer.index == 0 || this.players.length <= 1 )
        this.activePlayer.seekTo(0)
      else  {
        this.activePlayer.pause();
        this.lastActivePlayer = this.activePlayer;
        this.activePlayer = this.players[this.activePlayer.index - 1]
        this.activePlayer.initIfNeeded(() => {
          this.activePlayer.element.scrollIntoView()
          this.activePlayer.play(0)
        })
      }
    } else if ( target.hasClass("forward") ) {
      if ( this.activePlayer.index >= this.players.length - 1 )
        return

      this.activePlayer.pause();

      let nextPlayer = this.players[this.activePlayer.index + 1]

      nextPlayer.initIfNeeded(() => {
        this.lastActivePlayer = this.activePlayer;
        this.activePlayer = nextPlayer
        this.activePlayer.play()
        this.resetStates()
        this.activePlayer.element.scrollIntoView()
      })
    } else if ( target.hasClass("footer-share-mobile") ) {
      window.shareModal.shareChoice = true
      window.shareModal.showModal()
    }
  }

  playPause() {
    if ( !this.players.length )
      return

    if ( this.activePlayer ) {
      this.activePlayer.pause()
      this.lastActivePlayer = this.activePlayer
      this.activePlayer = null
    } else {
      if ( !this.lastActivePlayer ) {
        this.lastActivePlayer = this.players[0]
      }

      this.lastActivePlayer.play()
      this.activePlayer = this.lastActivePlayer
    }
    this.resetStates()
  }

  mixPositions() {
    let h = {}
    this.players.forEach(player => {
      h[player.mixID] = player.lastTime
    })
    return h
  }

  onFinish(player) {
    if ( player == this.activePlayer )
      this.activePlayer = null

    player.seekTo(0)
    this.resetStates()
  }

  firstLinked() {
    return this.players.find((p) => {
      const r = p.region();
      return r && r.data && r.data.linked
    });
  }

  loopRegion(player, region) {
    this._suppressCallbacks(() => {
      // odd corner case; if there's other regions hanging out there,
      // this comes in as a non-linked region.

      const otherRegions = this.players.find((p) => p.region());

      if ( !otherRegions ) {
        this.players.forEach((p) => {
          if ( !p.region() && p != player && p.songID == player.songID ) {
            p.addLinkedRegion({
              start: region.start,
              end: region.end
            });
          }
        });

        region.data['linked'] = true;
      }

    });
  }

  _suppressCallbacks(fn) {
    if ( this.inUpdateLoop )
      return;

    this.inUpdateLoop = true;
    const ret = fn()
    this.inUpdateLoop = false;
    return ret;
  }

  regionUpdated(region) {
    this._suppressCallbacks(() =>
      this.players.forEach((player) => player.otherRegionUpdated(region))
    );

    if ( this.activePlayer && this.activePlayer.region() == region ) {
      this.activePlayer.ws.setPlayEnd(region.end);
    }
  }

  regionRemoved(region) {
    this._suppressCallbacks(() => {
      this.players.forEach((player) => player.otherRegionRemoved(region))
    });
  }

  crossFade(oldPlayer, oldInitial, newPlayer, newTarget, interval, callback) {
    let intervals = 10
    let oldStep = oldInitial / intervals
    let newStep = newTarget /intervals

    if ( interval == intervals ) {
      callback()
    } else {
      oldPlayer.ws.setVolume(oldInitial - (oldStep * interval))
      newPlayer.ws.setVolume(newTarget - ((10 - interval) * newStep))
      setTimeout(() => this.crossFade(oldPlayer, oldInitial, newPlayer, newTarget, interval + 1, callback), 20)
    }
  }
  doAB(player) {
    let mix_align = new MixAlign(this.activePlayer.ws, player.ws)
    let oldPlayer = this.activePlayer

    let originalVol = oldPlayer.volume.gain.toGainValue()
    let newVol = player.volume.gain.toGainValue()

    player.pauseEvents = true
    player.setSpinner()

    return mix_align.align().then(() => {
      this.crossFade(oldPlayer, originalVol, player, newVol, 0, () => {
        player.pauseEvents = false
        this.activePlayer = player
        oldPlayer.pause();
        oldPlayer.ws.setVolume(originalVol);
        this.resetStates();
      })
    })
  }

  playPauseClick(player) {
    if ( this.pauseEvents )
      return;

    if ( !this.activePlayer ) {
      this.activePlayer = player;
      player.play();

    } else if ( this.activePlayer == player ) {
      this.activePlayer = null;
      player.pause();
    } else {
      this.lastActivePlayer = this.activePlayer;
      this.activePlayer.pause();

      this.activePlayer = player;
      player.play();
    }
  }

  abClick(player) {
    this.lastActivePlayer = this.activePlayer;
    this.pauseEvents = true;
    this.doAB(player).then(() => {
      this.pauseEvents = false
    });
  }

  playAtTime(player, time) {
    if ( this.activePlayer && this.activePlayer != player )
      this.activePlayer.pause()

    player.seekTo(time);
    player.play();
    this.activePlayer = player;
  }

  resetStates() {
    this.players.forEach((player) => {
      let play = player.controls.find('.play-icon');
      let pause = player.controls.find('.pause-icon');
      let ab = player.controls.find('.ab-text');

      player.hideSpinner();
      player.hideLoop();
      if ( !this.activePlayer ) {
        ab.addClass("disabled")
        pause.hide()
        play.show()

      } else if ( this.activePlayer == player ) {
        play.hide()
        ab.addClass('disabled')
        pause.show()

        player.open(true);
        $(".song-title").html(player.title);
        $(".song-title").parent().css('display', 'flex');
      } else {
        /* keep open only the player before this one that was playing. */
        if ( player != this.lastActivePlayer )
          player.close(true);

        play.show()
        pause.hide()
        ab.removeClass('disabled')
      }
    });

    let gc = $(".player-global-controls")

    if ( this.activePlayer ) {
      gc.find(".play-icon").hide()
      gc.find(".pause-icon").show()
      gc.find("svg").removeClass("disabled")

      if ( this.activePlayer.index == this.players.length - 1 )
        gc.find(".forward").addClass("disabled")
    } else {
      gc.find(".play-icon").show()
      gc.find(".pause-icon").hide()
      gc.find("svg").addClass("disabled")
      gc.find(".play-icon").removeClass("disabled")
    }
  }
}

$( document ).ready(() => {
  window.mixCollection = new MixCollection()
});

$(document).on('keypress', function(e) {
  var tag = e.target.tagName.toLowerCase();
  if ( e.which === 32 && tag != 'input' && tag != 'textarea') {
    e.preventDefault()
    window.mixCollection.playPause()
  }
});


