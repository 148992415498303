<template>
  <span>
    <autocomplete ref="autocomplete" :search="searchArtists" :defaultValue="artist" @input="update($event.target.value)" @submit="update"></autocomplete>
    <input type="hidden" :name="elName" :value="myArtist">
  </span>
</template>

<script>
  import Autocomplete from '@trevoreyre/autocomplete-vue'
  export default {
    components: { Autocomplete },
    data() { 
      return { 
        myArtist: this.artist
      }
    },
    props: [ "artist", "artists", "elName" ],
    methods: {
      update(result) { 
        this.myArtist = result
      },
      searchArtists(input) {
        let matched = this.artists.filter(a => a.toLowerCase().includes(input.toLowerCase()));
        return(matched);
      },
    }
  }
</script>
