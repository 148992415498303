export default function(link, url, title, objectType) {
  $(link).click((e) => {
    let modal = window.shareModal
    modal.shareTitle = title
    modal.createShareURL = url
    modal.shareObjectType = objectType
    modal.shareChoice = false
    modal.showModal()
  });
}
