import Vue from 'vue'

import ShareModal from './share_modal.vue'
let ShareModalVue = Vue.extend(ShareModal)

$(document).ready(() => {
  if ( document.getElementById("share-modal") ) {
    window.shareModal = new ShareModalVue()
    window.shareModal.$mount('#share-modal')
  }
})

