<template>
  <div class="mix-notes col w-100">
    <Note v-for="(note, index) in sortedNotes"
      :note="note"
      :owns-mix="ownsMix"
      :key="note.id"
      :indent="0"
      @updateNote="postUpdate($event)"
      @deleteNote="deleteNote($event)"
      @startReply="startReply($event)"
      @noteReaction="noteReaction($event)"
    >
    </Note>
    <div v-if="nCompleted > 0" class="show-completed form-check">
      <input class="form-check-input" type="checkbox" v-on:change="showCompleted = !showCompleted" id="show-completed-checkbox">
      <label class="form-check-label" for="show-completed-checkbox">Show {{ nCompleted }} completed note(s)</label>
    </div>
  </div>
</template>

<script>
import Note from "./note.vue";
import Vue from 'vue';
import { formatISO, parseJSON, compareAsc} from "date-fns";


const parseNoteJSON = function(json) {
  const note = Object.assign({}, json);
  note.created_at = parseJSON(note.created_at);
  note.replies = [];
  return note;
}

export default {
  props: ["notes", "mix_id", "owns-mix"],
  data: function() {
    let notesByID = {};

    this.notes.forEach((json) => {
      const note = parseNoteJSON(json);
      notesByID[note.id] = note
    })

    return {
      notesByID: notesByID,
      showCompleted: false
    }
  },
  components: { "Note": Note },
  methods: {
    startReply(note) {
      let n = this.notesByID[note.id]
      if ( !n ) return
      
      let reply = this.buildNote({ parent_note_id: note.id})
      this.$set(this.notesByID, reply.id, reply)
    },
    addNote() {
    },
    postUpdate(note) {
      var url;
      var method;

      if ( note.unsaved ) {
        url = '/notes.json';
        method = "post";
      } else {
        url = `/notes/${note.id}.json`;
        Vue.set(this.notesByID, note.id, note);
        method = "put";
      }

      if ( !mmmGlobals.logged_in ) {
        note.anonymous_name = window.localStorage.username
      }

      $.ajax(url,  {
        method: method,
        data: { note: note},
        dataType: 'json',
        success: (json) => {
          const n = parseNoteJSON(json.note);
          Vue.set(this.notesByID, n.id, n);
          if ( note.unsaved ) {
            Vue.delete(this.notesByID, note.id)
          }
        }
      })
    },
    deleteNote(note_id) {
      let note = this.notesByID[note_id]
      if ( note.unsaved ) { 
        Vue.delete(this.notesByID, note_id);
        return
      }

      $.ajax(`/notes/${note_id}.json`, {
        method: "delete",
        success: () => {
          Vue.delete(this.notesByID, note_id);
        }
      });
    },
    buildNote(attributes) {
      return Object.assign({}, {
        id: new Date().toISOString(),
        unsaved: true,
        created_at: new Date(),
        username: mmmGlobals.username,
        mix_id: this.mix_id,
        note: ""
      }, attributes || {})
    },
    noteReaction(ev) {
      let reaction = ev.reaction
      let note_id = ev.note_id

      let reactionNote = this.buildNote({ parent_note_id: ev.note_id, note: ":like:"} )
      this.postUpdate(reactionNote)
    }
  },
  computed: {
    sortedNotes: function() {
      var sorted = Object.values(this.notesByID);

      sorted.forEach(note => { 
        note.replies = [] 
        note.reactions = {}
      }) 

      if ( !this.showCompleted ) {
        sorted = sorted.filter((n) => !n.completed);
      }

      sorted.sort((a, b) => compareAsc(b.created_at, a.created_at));

      if ( !sorted[0] || !sorted[0].editing ) {
        sorted.unshift(this.buildNote())
      }

      sorted.forEach(note => {
        if ( note.parent_note_id ) {
          let parentNote = this.notesByID[note.parent_note_id]
          if ( parentNote ) { 
            if ( note.note == ":like:" ) {
              if ( !parentNote.reactions['like'] )
                parentNote.reactions['like'] = []

              parentNote.reactions['like'].push(note)
            } else {
              parentNote.replies.push(note)
            }
          }
        }
      })

      sorted = sorted.filter(note => !note.parent_note_id)

      return sorted
    },
    nCompleted: function() {
      const values = Object.values(this.notesByID);
      return values.filter((n) => n.completed).length;
    }
  }
}
</script>

