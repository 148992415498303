<template>
  <div :class="activeClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: [ "dragClass" ],
  data() {
    return { activeClass: "" }
  },
  mounted() {
    let el = $(this.$el).children().first()

    el.on("dragenter", e => {
      e.preventDefault()
      this.$emit("dragstart")
      this.activeClass = this.dragClass
      this.dragging = true
    })

    el.on("dragleave", e => {
      e.preventDefault()
      this.$emit("dragend")
      this.activeClass = ""
      this.dragging = false
    })

    el.on("dragover", e => e.preventDefault())

    el.on("drop", (e) => {
      e.preventDefault()

      let files = e.originalEvent.dataTransfer.files

      let input = el.find("input[type='file']")

      input[0].files = files

      let ev = {
        target: input[0]
      }

      this.$emit("change", ev)
      this.activeClass = ""
      this.$emit("dragend")
    });
  }
}

</script>
