<template>
  <b-modal id="share-modal" height="auto" :hide-header="true" :hide-footer="true" size="lg" body-class="share-modal" :no-close-on-backdrop="shareChoice">
    <div class="share-modal-title">
      <span v-if="shareChoiceData">
        What shall we share?
      </span>
      <span v-else>
        Sharing {{ shareObjectTypeData }}: "{{ shareTitleData }}"
      </span>
      <img src="/images/trumpet-white.svg" id="share-trumpet" class="d-none d-sm-inline-block"/>
      <button type="button" class="close share-modal-close" aria-label="Close" @click="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div v-if="shareChoiceData" class="p-2">
      <share-choice @choice="onShareChoice"/>
    </div>
    <div v-else class="p-2">
      <b-tabs content-class="mt-3" @input="tabChanged">
        <b-tab title="Copy Link" active>
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                Copy and paste this link to share via text, whatsapp, facebook..
              </div>
            </div>
            <div class="row py-4">
              <div class="col-12 col-sm-8" style="align-self: center">
                <input type="text" style="width: 100%" ref="shareURL" :value="shareURL" readonly @click="shareURLClick($event)">
              </div>
              <div class="col-auto mt-2 mt-sm-0">
                <button type="button" class="btn btn-primary sharelink-copy" @click="copyURLClick()" aria-label="Copy">Copy</button>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Email">
          <div class="container-fluid">
            <div class="form-group row">
              <label for="email" class="col-12 col-sm-4 col-form-label">Email addresses:</label>
              <div class="col-12 col-sm-7">
                <vue-tags-input
                  v-model="email"
                  :add-on-key="[',', ' ', 13]"
                  placeholder="Add email"
                  :tags="emails"
                  :autocomplete-items="autocompleteEmails"
                  @tags-changed="newTags => emails = newTags"
                />
                <ErrorList field="email" :errors="errors['email']"/>
              </div>
            </div>
            <div class="row">
              <label for="message" class="col-12 col-sm-4 col-form-label">Message:</label>
              <div class="col-12 col-sm-7">
                <textarea class="form-control" v-model="message" rows=5 />
              </div>
            </div>
            <div class="form-group row py-2">
              <div class="offset-sm-4 col-auto">
                <button class="btn btn-primary" type="button" @click="share()" :disabled="state != 'pending'">
                  <span v-if="state == 'sharing'">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Share
                  </span>
                  <span v-else-if="state == 'sent'">
                    <img src="/images/thumbs_up.svg" style="height: 1em; width: 1em; margin-right: 5px;"/>sent!
                  </span>
                  <span v-else>
                    Share
                  </span>
                </button>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<style>
.share-modal {
  padding: 0px !important;
}
</style>
<script>

import fetch_csrf from 'utils/csrf'
import ErrorList from './error_list.vue'
import axios from 'axios'
import ShareChoice from './share_choice.vue'

export default {
  data() {
    return {
      errors: {},
      message: "",
      email: "",
      emails: [],
      shareURL: null,
      state: 'pending',
      shareTitleData: "",
      createShareURLData: "",
      shareObjectTypeData: "",
      shareChoiceData: false,
      emailsForAutocomplete: null
    }
  },
  props: ["shareTitle", "createShareURL", "shareObjectType", "shareChoice" ],
  methods: {
    reset() {
      this.errors = {}
      this.setMessageDefault()
      this.email = ""
      this.emails = []
      this.shareURL = null
      this.state = "pending"
    },
    validityClass(element) {
      if ( this.errors[element] )
        return "is-invalid"
      else
        return ""
    },
    showModal() {
      this.shareTitleData = this.shareTitle
      this.createShareURLData = this.createShareURL
      this.shareObjectTypeData = this.shareObjectType
      this.shareChoiceData = this.shareChoice
      this.reset()
      if ( this.createShareURLData ) 
        this.getShareLink()
      axios("/share/emails.json").then(r => {
        this.emailsForAutocomplete = r.data.map(e => {
          return { text: e }
        })
      })

      this.$bvModal.show("share-modal")
    },
    getShareLink() { 
      this.doShare({share_type: "link"}).then(r =>
        this.shareURL = r.url
      )
    },
    closeModal() {
      this.$bvModal.hide("share-modal")
      this.state = 'pending'
    },
    shareURLClick(ev) {
      ev.target.select()
    },
    copyURLClick() {
      this.$refs.shareURL.focus()
      this.$refs.shareURL.select()
      document.execCommand("copy");
    },
    doShare(data) {
      return fetch_csrf(this.createShareURLData, {
        method: "post",
        body: JSON.stringify(data),
        headers: { 'Content-type': 'application/json' }
      }).then(r => {
        if (!r.ok) {
          if ( r.status == 422 )
            return r.json().then(j => {
              let err = new Error("Validation error:")
              err.body = j
              throw err
            })
          else
            throw new Error("HTTP error " + r.status)
        }
        return r.json()
      })
    },
    tabChanged(idx) {
      if ( idx == 1 && !this.shareURL ) {
      }
    },
    share() {
      this.state = 'sharing'

      let postData = {
        share_type: "email",
        email: this.emails.map(e => e.text).join(' '),
        message: this.message
      }

      this.doShare(postData).then(r => {
        this.state = 'sent'
        setTimeout(() => this.closeModal(), 1500)
      }).catch((err) => {
        this.state = 'pending'
        this.errors = err.body.errors
      })
    },
    setMessageDefault() {
      this.message = `I'm sharing '${this.shareTitleData}' with you on via Mixbrite.  Have a listen!`
    },
    onShareChoice(choice) {
      this.shareTitleData = choice.label
      this.shareObjectTypeData = choice.objectType
      this.createShareURLData = choice.shareURL
      this.shareChoiceData = false
      this.getShareLink()
    }
  },
  computed: {
    autocompleteEmails () {
      let emails = this.emailsForAutocomplete

      if ( emails == null || this.email == null ) return []

      return emails.filter(i => {
        return i.text.toLowerCase().indexOf(this.email.toLowerCase()) !== -1;
      });
    },
  },
  watch: {
    shareTitleData: function(title) {
      this.setMessageDefault()
    }
  },
  components: { ErrorList, ShareChoice }
}
</script>

