const TOLERANCE = 0.01
export default class MixAlign {

  constructor(leader, follower) {
    this.leader = leader
    this.follower = follower
    this.followerVolume = follower.getVolume()

    this.pushAheadTime = this.isSafari() ? 1.0 : 0.1
  }


  isSafari() {
    let ua = navigator.userAgent
    return /Safari/.test(ua) && !(/Chrome/.test(ua))
  }

  async align() {
    this.follower.setVolume(0)

    return new Promise(async (resolve, reject) => {
      await this.follower.play()
      this.waitForMotion(resolve)
    })
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async waitForMotion(callback) {
    this.follower.setCurrentTime(this.leaderTime() + this.pushAheadTime)
    this.lastFollowerTime = this.followerTime()

    console.log("waiting for follower to begin motion.  leader: " + this.leaderTime())
    while ( this.lastFollowerTime > this.follower.getCurrentTime() )
      await this.timeout(50)

    this.shuttleStop(callback)
  }

  leaderTime() {
    return this.leader.getCurrentTime()
  }

  followerTime() {
    return this.follower.getCurrentTime()
  }

  /* at this point the follower is playing back sometime ahead
   * of the leader.  we pause/play the follower in a tight loop
   * until we reach reasonable sync */

  async shuttleStop(callback) {
    let paused = false

    await this.timeout(10)
    while(true ) {
      let lTime = this.leaderTime()
      let fTime = this.followerTime()

      console.log("shuttleSync: " + lTime + ", " + fTime + ", " + (paused ? "paused" : "playing"))

      if ( !this.leader.isPlaying() )
        return

      if ( fTime < lTime + 0.005 )  {

        if ( paused )
          await this.follower.play()

        return callback()
      } else if ( paused ) {
        await this.follower.play()
        paused = false
      } else {
        this.follower.pause()
        paused = true
      }

      await this.timeout(20)
    }
  }
}
