import Vue from 'vue'
import Upload from './upload.vue'

$(document).ready(() => {
  $("#upload-form").each( (_, div) => {
    new Vue({
      el: div,
      render: h => h(Upload, { props: { autocompleteData: $(div).data("autocomplete-data") } })
    })
  });
})


