export default function fetch_csrf(url, options) {
  const token = document.querySelector('meta[name=csrf-token]').content;
  if ( !options )
    options = {};
  if ( !options.headers )
    options.headers = {};

  options.headers['X-CSRF-Token'] = token;
  return fetch(url, options);
}

export function post_csrf(url, data, options) { 
   const postOptions = { 
      method: "post",
      body: JSON.stringify(data),
      headers: { 'Content-type': 'application/json' },
      ...(options || {})
    }
   return fetch_csrf(url, postOptions)
}
