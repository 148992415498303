import Vue from 'vue'
import ArtistAutocomplete from './artist_autocomplete.vue'

const classes = { ArtistAutocomplete }

$(document).ready(() => {
  $(".vue").each( (_, el) => {
    const data = $(el).data()
    const vueClass = classes[data.vueClass]

    new Vue({
      el: el,
      render: h => h(vueClass, { props: data } )
    })
  });
})

