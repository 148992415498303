import Vue from 'vue'

import { TabsPlugin, ModalPlugin, DropdownPlugin, PopoverPlugin } from 'bootstrap-vue'
Vue.use(TabsPlugin)
Vue.use(ModalPlugin)
Vue.use(DropdownPlugin)
Vue.use(PopoverPlugin)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import VueTagsInput from '@johmun/vue-tags-input'
Vue.use(VueTagsInput)

import "mixmastermiles/note";
import "mixmastermiles/upload";
import "mixmastermiles/share_modal";
import "mixmastermiles/add_to_playlist_modal";
import "mixmastermiles/ios_install";
import "mixmastermiles/vue";

import setupSharing from "mix_player/sharing.js";

import SVGInject from '@iconfu/svg-inject'
import { post_csrf } from '../utils/csrf'

window.SVGInject = SVGInject


$( document ).ready(() =>  {
  const selectArtist = $("#select-artist");
  selectArtist.change( (ev) => {
    let uri = "";

    if ( selectArtist.val() != "all" ) {
      uri = "/artists/" + selectArtist.val();
    }

    let path = window.location.pathname
    if ( path.match(/latest/)) {
      uri = uri + "/latest"
    }

    window.location.pathname = uri;
  });

  let artistShareLink = $(".artist-share-link")[0];
  if ( artistShareLink ) {
    let artistID = $(artistShareLink).data("artist-id")
    let artistName = $(artistShareLink).data("artist-name")
    setupSharing($(".artist-share-link"), '/share/artist/' + artistID + '.json', artistName, "artist");
  }
  
  let playlistShareLink = $(".playlist-share-link")[0];
  if ( playlistShareLink ) {
    let playlistID = $(playlistShareLink).data("playlist-id")
    let playlistName = $(playlistShareLink).data("playlist-name")
    setupSharing($(".playlist-share-link"), '/share/playlist/' + playlistID + '.json', playlistName, "playlist");
  }


  $(".footer-share-link").click(e => {
    let modal = window.shareModal
    modal.shareChoice = true
    modal.showModal()
    e.preventDefault()
  })

  if ( window.innerWidth < 500 ) {
    $(".remove-on-mobile").each((i, el) => { $(el).removeClass("carousel-item") ; $(el).css("display", "none") })
  }

  $("#nav-toggle").click(() => { 
     $(".sidebar").toggleClass('d-none');
  })

  $("#sidebar-more-artist").click(() => { 
     $("#sidebar-more-artist").hide();
     $(".artist-hidden-more").toggleClass('artist-hidden-more');
  })

  $('.toast').toast('show');


  if ( /\/playlists\/\d+/.test(window.location.pathname) ) {
    $(".mix-list").sortable({
      handle: ".drag-handle",
      stop: (event) => { 
        const newItemList = $(".mix-list").children().toArray().map(d => $(d).data().playlistItemId)
        post_csrf(window.location.pathname + "/reorder", { playlist_item_ids: newItemList })
      }
    })
  }

})

