<template>
  <div class="p-3">
    <div class="d-flex">
      <vue-select v-model="choice" :options="choices" placeholder="Choose an item to share..." class="flex-grow-1 mr-2">
        <template slot="option" slot-scope="option">
          <span class="fa" :class="option.icon"></span>
          {{ option.label }}
        </template>
      </vue-select>
      <button :disabled="!choice" class="btn btn-primary btn-sm" @click.prevent="$emit('choice', choice)">Next</button>
    </div>
    <div class="mt-3 text-dark" v-if="choice && choice.objectType == 'artist'">
      Sharing an artist will allow people to hear any mixes uploaded by this artist in the future.
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelect from 'vue-select'

export default {
  components: { VueSelect },
  data() {
    this.fetchShareChoiceData()

    return {
      choice: null,
      artists: [],
      mixes: [],
    }
  },
  methods: {
    fetchShareChoiceData() {
      axios("/artists.json").then(r => {
        this.artists = r.data.map(a => {
          return {
            label: "Latest mixes by " + a.name,
            objectType: 'artist',
            icon: 'fa-user',
            shareURL: "/share/artist/" + a.id + ".json"
          }
        })
      })
      axios("/mixes.json").then(r => {
        this.mixes = r.data.map(m => {
          var label = m.name
          if ( m.version ) {
            if ( m.version.match(/^\d+$/) ) {
              label = label + "#" + m.version
            } else {
              label = label + " - " + m.version
            }
          }
          return {
            icon: 'fa-music',
            objectType: 'mix',
            label: label,
            shareURL: "/share/mix/" + m.id + ".json"
          }
        })
      })
    }
  },
  computed: {
    choices() {
      return this.artists.concat(this.mixes)
    }
  }
}

</script>
