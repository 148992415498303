import fetch_csrf from 'utils/csrf';

class Gain {
  constructor(dbs) {
    this.dbs = dbs;
  }

  static fromSliderValue(value) {
    if ( value > 1 ) {
      let offset = (value - 1.0) * 1.98;
      value = value + offset;
    }

    let dbs = (20.0 * (Math.log(value)/Math.LN10)).toFixed(1);

    return new Gain(dbs);
  }


  toGainValue() {
    return Math.pow(10, this.dbs/20);
  }

  toSliderValue() {
    let gain = this.toGainValue();
    if ( gain > 1 ) {
      // 1 -> offset is basically 0
      // 3.98 -> offset should be about 1.98
      let offset = (gain - 1) / 2.98;
      gain = 1 + offset;
    }
    return gain;
  }
}


export default class Volume {
  constructor(slider, text, ws, mixID) {
    this.slider = slider;
    this.text = text;
    this.ws = ws;
    this.mixID = mixID;
    this.sliderStep = 0.01;
    this.gain = new Gain(0);

    // input is fired as we're dragging
    slider.on('input', (e) => {
      this.gain = Gain.fromSliderValue(parseFloat(e.target.value));
      this.gainChanged(false);
    });

    // change is fired once we've released or I guess on keyboard input?
    slider.on('change', (e) => {
      this.gain = Gain.fromSliderValue(parseFloat(e.target.value));
      this.gainChanged(true);
    });

    slider.on('click', (e) => {
      if ( e.altKey ) {
        this.gain = new Gain(0);
        this.slider.val(this.gain.toSliderValue());
        this.gainChanged(true);
      }
    });

    slider[0].min = 0;
    slider[0].max = 2;
    slider[0].step = this.sliderStep;

    this.initVolume(0);
  }

  initVolume(dbs) {
    this.gain = new Gain(dbs);
    this.initialVolume = new Gain(dbs);
    this.slider.val(this.gain.toSliderValue());
    this.gainChanged(false);
  }


  initVolumeOffset(dbs) {
    let gain = new Gain(dbs);
    let sliderVal = this.sliderStep* Math.floor(gain.toSliderValue() / this.sliderStep);
    let datalistID = `notch-${this.mixID}`;
    let datalist = $(`<datalist id='${datalistID}'><option>${sliderVal}</option><option>1</option></datalist>`)

    $(this.slider).parent().append(datalist);
    $(this.slider).attr("list", datalistID);
  }

  scalePeaks(gain) {
    if ( !this.ws.backend.unscaledPeaks ) {
      this.ws.backend.unscaledPeaks = this.ws.backend.peaks.slice();
    }

    this.ws.backend.peaks = this.ws.backend.unscaledPeaks.map((p) => p * gain);
    this.ws.drawBuffer();
  }

  gainChanged(shouldPutVolume) {
    if ( this.ws.backend && this.ws.backend.peaks ) {
      this.scalePeaks(this.gain.toGainValue());
    }

    this.ws.setVolume(this.gain.toGainValue());
    this.displayVolume(this.gain.dbs);
    if ( shouldPutVolume ) {
      this.storeVolume();
    }
  }

  storeVolume() {
    fetch_csrf('/mixes/' + this.mixID, {
      method: "put",
      body: JSON.stringify({volume: this.gain.dbs}),
      headers: {
        'Content-type': 'application/json'
      }
    });
  }

  displayVolume(gain) {
    let dbStr = "";

    if ( gain == "-Infinity" )
      dbStr = "-∞db";
    else {
      if ( gain > 0 )
        dbStr += "+";

      if ( gain.toFixed )
        dbStr += gain.toFixed(1);
      else
        dbStr += gain

      dbStr += "db";
    }

    this.text.html(dbStr);
  }
}
