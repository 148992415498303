<template>
  <div :style="{'padding-left': indentLevel}">
    <div class="note no-gutters" ref="note">
      <div
        class="d-none d-lg-block note-dot"
        :title="username"
        :class="(this.editing || this.noteData.unsaved) && 'editing'">{{ initials }}</div>
      <div v-if="isEditing" class="note-edit">
        <div class="note-anon-username" v-if="needsUsername">
          <input type="text"
            class="text-note form-control"
            @keyup.enter="enterUsername"
            v-model="anonUsername"
            placeholder="enter your full name to add a mix note">
        </div>
        <div class="note-edit-container">
          <input type="text"
            ref="input"
            class="text-note form-control w-auto flex-grow-1"
            @keyup.enter="finishEditing"
            v-model="noteData.note"
            @focus="noteFocus"
            placeholder="enter mix notes here...">
          <div v-if="showButtons" class="ml-0 ml-md-2 mt-2 mt-md-0">
            <button type="button"
              :disabled="noteData.note == null || noteData.note.length == 0 || (needsUsername && !anonUsername) || saving"
              @click.prevent="finishEditing"
              class="btn btn-dark">
              <i v-if="saving" class="fa fa-spinner fa-spin"></i>
              <span v-else>Save</span>
            </button>
            <button type="button"
              @click.prevent="cancelEditing"
              :disabled="saving"
              class="btn btn-dark">Cancel</button>
          </div>
        </div>
      </div>
      <div v-else class="col w-100 mix-note-text-group pl-0 pl-lg-1">
        <div v-bind:class="{ 'mix-note-text-completed': noteData.completed }">
          <a href="#" v-if="noteTime" @click.prevent="noteTimeClick(noteTime)">{{ noteTime }}</a>
          {{ noteText}}
        </div>
        <div class="mix-note-text-date d-flex align-items-center">
          <span class="mr-2">
            {{ noteDate }} ago
          </span>
          <div class="mix-note-reactions">
            <span v-if="noteData.reactions['like']"  v-b-popover.hover.top="reactionText('like')"
              class="reaction-dot"><i class="far fa-thumbs-up"></i>
              <div class="reaction-count" v-if="reactionCount('like') > 1">{{ reactionCount('like') }}</div>
            </span>
          </div>
        </div>
        <div class="mix-note-controls">
          <LikeButton @like="doLike"/>
          <a v-if="canDone" href="#" @click.prevent="toggleDone"><i class="fa fa-check mr-1"></i>Done</a>
          <a href="#" @click.prevent="replyClick">Reply</a>
          <b-dropdown class="note-dropdown" html="<i class='fa fa-ellipsis-h'></i>" variant="outline-primary" size="sm">
            <b-dropdown-item v-if="canEdit" @click.prevent="startEdit"><i class="fa fa-pencil mr-1"></i>Edit</b-dropdown-item>
            <b-dropdown-item v-if="canDelete" @click.prevent="deleteNote"><i class="fa fa-trash mr-1"></i>Delete</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="row no-gutters pr-0">
      <div class="w-100">
        <note v-for="n in noteData.replies" 
          @updateNote="$emit('updateNote', $event)" 
          @deleteNote="$emit('deleteNote', $event)" 
          @startReply="$emit('startReply', $event)" 
          @noteReaction="$emit('noteReaction', $event)"
          :indent="indent + 1"
          :note="n" 
          :owns-mix="ownsMix" 
          :key="n.id"/>
      </div>
    </div>
  </div>
</template>

<script>

import { parseJSON, formatDistance } from 'date-fns';
import timeFormat from 'utils/time_format'
import LikeButton from './like_button.vue'

export default {
  props: ["note", "ownsMix", "indent"],
  components: { LikeButton },
  name: "note",
  data: function() {
    return {
      anonUsername: "",
      noteData: this.note,
      editing: false,
      saving: false, 
      savedNote: "",
      showButtons: this.note.parent_note_id
    }
  },
  methods: {
    noteFocus() {
      if ( this.noteData.note ) return
      let mixPositions = mixCollection.mixPositions()
      let currentTime = mixCollection.mixPositions()[this.noteData.mix_id]
      if ( currentTime ) {
        this.noteData.note = `(${timeFormat(currentTime)}) `
      }
      this.showButtons = true
    },
    startEdit() {
      this.savedNote = this.noteData.note
      this.editing = true
      this.showButtons = true
    },
    cancelEditing() {
      if ( this.noteData.parent_note_id && this.noteData.unsaved ) 
        return this.deleteNote()

      this.noteData.note = this.savedNote
      this.editing = false
      this.showButtons = false
    },
    noteBlur() {
      this.showButtons = false
    },
    deleteNote() {
      this.$emit('deleteNote', this.noteData.id);
    },
    enterUsername() {
      if ( this.anonUsername ) {
        this.noteData.username = this.anonUsername
        window.localStorage.username = this.anonUsername
        this.anonUsername = ""
      }
    },
    finishEditing(event) {
      this.enterUsername()

      if ( this.note.id )
        this.editing = false

      this.saving = true
      this.$emit('updateNote', this.noteData);
    },
    toggleDone() {
      this.noteData.completed = !this.noteData.completed;
      this.$emit('updateNote', this.noteData);
    },
    noteTimeClick(timeStr) {
      let player = $(this.$refs.note).parents('.player')
      let mixPlayer = player.data('mix-player')

      let [m, s] = timeStr.replace(/[\(\)]/g, "").split(":").map(i => parseInt(i))
      let time = m * 60 + s
      window.mixCollection.playAtTime(mixPlayer, time)
    },
    replyClick() {
      let unsavedReply = this.noteData.replies.find(r => r.unsaved)
      if ( unsavedReply ) {
        this.$emit("deleteNote", unsavedReply.id)
      } else {
        this.$emit('startReply', this.noteData)
      }
    },
    doLike() {
      this.$emit("noteReaction", { reaction: "like", note_id: this.noteData.id})
    },
    reactionUsernames(reaction) { 
      let reactions = this.noteData.reactions[reaction]
      let users = {}
      if ( reactions.length == 0 )
        return ""

      for ( let n of reactions ) {
        users[n.username || "anonymous"] = 1
      }

      return Object.keys(users)
    },
    reactionText(reaction) {
      let sortedNames = this.reactionUsernames(reaction).sort()

      return "Liked by " + sortedNames.join(", ")
    },
    reactionCount(reaction) {
      return this.reactionUsernames(reaction).length
    }
  },
  mounted() {
    if ( this.noteData.unsaved && this.noteData.parent_note_id ) {
      this.$nextTick().then(() => this.$refs['input'].focus())
    }
  },
  computed: {
    indentLevel() {
      return (this.indent * 15) + "px"
    },
    isEditing() {
      return this.editing || this.noteData.unsaved;
    },
    loggedIn() {
      return mmmGlobals.logged_in
    },
    timeAndText() {
      let time
      let text = this.note.note
      let match = text.match(/\((\d+:\d+\d+)\)/)

      if ( match ) {
        text = text.substr(match.index + match[0].length, text.length - 1)
        time = match[0]
      }
      return [text, time]
    },
    noteText() {
      return this.timeAndText[0]
    },
    noteTime() {
      return this.timeAndText[1]
    },
    needsUsername() {
      return !this.noteData.username && !window.localStorage.username
    },
    username() {
      return this.noteData.username || this.anonUsername || window.localStorage.username
    },
    initials() {
      let username = this.username
      if ( username ) {
        var split = username.split(/\s+/).filter(s => s.length > 0)
        if ( split.length > 1 )
          return (split[0][0] + split[split.length - 1][0] || "").toUpperCase()
        else
          return username[0].toUpperCase()
      } else {
        return "?"
      }
    },
    canEdit() {
      if ( mmmGlobals.user_id == -1 ) 
        return false

      return this.noteData.user_id == mmmGlobals.user_id
    },
    canDone() { 
      if ( mmmGlobals.user_id == -1 ) 
        return false

      return (!this.noteData.parent_note_id) && 
        (this.noteData.user_id == mmmGlobals.user_id || this.ownsMix)
    },
    canDelete() {
      if ( mmmGlobals.user_id == -1 ) 
        return false

      return this.noteData.user_id == mmmGlobals.user_id || this.ownsMix
    },
    noteDate() {
      const date = parseJSON(this.noteData.created_at);
      return formatDistance(date, new Date());
    },
  }
}
</script>

