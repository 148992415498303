<template>
  <div>
    <a href='#' @click.prevent='doLike'><i class="fa fa-thumbs-up mr-1"></i>Like</a>
  </div>
</template>
<script>
export default { 
  methods: {
    doLike() { 
      this.$emit("like")
    }
  }
}
</script>
