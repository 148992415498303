import Vue from 'vue'

import AddToPlaylistModal from './add_to_playlist_modal.vue'
const AddToPlaylistVue = Vue.extend(AddToPlaylistModal)

$(document).ready(() => {
 const globalData = $("#add-to-playlist-modal").data() 
  if ( document.getElementById("add-to-playlist-modal") ) {
    window.addToPlaylistModal = new AddToPlaylistVue()
    window.addToPlaylistModal.$mount('#add-to-playlist-modal')
  }

  $(".add-to-playlist-link").click((e) => {
    e.preventDefault()
    let modal = window.addToPlaylistModal
    let data = $(e.target).closest('a').data();
    modal._props = { ...data, ...globalData }
    modal.showModal(data['what'])
  });
})

