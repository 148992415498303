let iosCopyToClipboard = function(el) {
    var oldContentEditable = el.contentEditable,
        oldReadOnly = el.readOnly,
        range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    var s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand('copy');
}

$(document).ready(() => {
  $(".ios-install-link").click((ev) => {
    ev.preventDefault()

    $("#ios_copy_el").each((i, el) => {
      iosCopyToClipboard(el)
    })

    document.cookie = "appInstalled=true"
    window.location = "https://apps.apple.com/us/app/mix-master-miles/id1516224866"
  })

  $("#ios-close-install").click((ev) => {
    ev.preventDefault()
    document.cookie = "appInstalled=true"
    $(".ios-install-banner").hide()
  })
})
