import Vue from 'vue'
import Notes from './notes.vue'

$(document).ready(() => {
  $(".mix-notes").each( (_, div) => {
    const notes = $(div).data("notes");
    const mix_id = $(div).data("mix-id");
    const ownsMix = $(div).data("owns-mix");
    new Vue({
      el: div,
      render: h => h(Notes, { props: { notes: notes, mix_id: mix_id, "owns-mix": ownsMix } } )
    })
  });
})

