<template>
  <div>
    <div class="form-group">
      <file-drag-target @change="updateFiles($event)" drag-class="drag-target-active" class="drag-target">
        <div class="custom-file">
          <input class="custom-file-input" type="file" name="mix_files" id="mix_files" @input="updateFiles($event)" multiple/>
          <label class="custom-file-label" for="mix_files">
            <div v-if="files.length == 0">Drag files here or choose mixes...</div>
            <div v-else-if="files.length == 1"><pre>{{ files[0].name }}</pre></div>
            <div v-else>{{ files.length }} files...</div>
          </label>
        </div>
      </file-drag-target>
      <div>
        or...
      </div>
      <div id="dropbox-chooser">
      </div>
      <div>
        <small class="form-text">For the moment, we only accept MP3s.</small>
      </div>
    </div>


    <div v-for="(file, index) in files">
      <pre>{{ file.name }}</pre>
      <progress v-if="file.uploading" max="100" :value="file.progress" style="width: 100%"></progress>
      <div v-if="Object.keys(file.errors).length > 0" class="alert alert-danger">
        <ul>
          There were problems with your upload:
          <template v-for="(value, key) in file.errors">
            <li v-for="err in value"><b>{{ key }}</b>: <i>{{ err }}</i></li>
          </template>
        </ul>
      </div>
      <div v-if="file.uploaded" class="alert alert-success">
        Uploaded Successfully!
      </div>
      <div v-else>
        <div class="form-group row">
          <label :for="nameOf(index, 'artist_name')" class="col-sm-3 col-form-label">Artist:</label>
          <div class="col-sm-9" autocomplete-wrapper>
            <autocomplete ref="autocomplete" :search="searchArtists" @input="updateArtist($event.target.value, index)" @submit="updateArtist($event, index)"></autocomplete>
            <input type="hidden" :name="nameOf(index, 'artist_name')" :value="file.artist">
            <a href="#" @click.prevent.stop="artistAutocompleteClick(index)"><i class="autocomplete-caret fa fa-caret-down"></i></a>
          </div>
        </div>
        <div class="form-group row">
          <label :for="nameOf(index, 'song_name')" class="col-sm-3 col-form-label">Song:</label>
          <div class="col-sm-9 autocomplete-wrapper">
            <autocomplete ref="autocompleteTitle" :search="searchSongs(file)" @input="updateTitle($event.target.value, file)" @submit="updateTitle($event, file)"></autocomplete>
            <input type="hidden" :value="file.title" :id="nameOf(index, 'song_name')">
            <a href="#" @click.prevent.stop="titleAutocompleteClick(index)"><i class="autocomplete-caret fa fa-caret-down"></i></a>
          </div>
        </div>
        <div class="form-group row">
          <label for="nameOf(index, 'description')" class="col-sm-3 col-form-label">Description:</label>
          <div class="col-sm-9">
            <textarea class="form-control" v-model="file.description" placeholder="What's new in this version of the mix?"></textarea>
          </div>
        </div>
        <div class="form-group row" v-if="file.artist && file.title">
          <label class="col-sm-3 col-form-label">Version:</label>
          <div class="col-sm-9">
            <label class="col-form-label">
              {{ file.version }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <button :disabled="uploading" @click="startUpload(0)" class="btn btn-primary">{{ uploadButtonTitle }}</button>
    </div>
  </div>
</template>
<script>
  import jsmediatags from 'jsmediatags/dist/jsmediatags.min'
  import Autocomplete from '@trevoreyre/autocomplete-vue'

  import axios from 'axios'
  import FileDragTarget from './file_drag_target.vue'
  import FuzzySet from 'fuzzyset.js'

  function jaroWinklerDistance(s1, s2) {
    const jaroDistance = (s1, s2) => {
      if (s1 === s2) return 1;

      const maxDistance = Math.floor(Math.max(s1.length, s2.length) / 2) - 1;
      const matches1 = new Array(s1.length).fill(false);
      const matches2 = new Array(s2.length).fill(false);
      let matchingCharacters = 0;

      for (let i = 0; i < s1.length; i++) {
        const start = Math.max(0, i - maxDistance);
        const end = Math.min(i + maxDistance + 1, s2.length);

        for (let j = start; j < end; j++) {
          if (!matches2[j] && s1[i] === s2[j]) {
            matches1[i] = true;
            matches2[j] = true;
            matchingCharacters++;
            break;
          }
        }
      }

      if (matchingCharacters === 0) return 0;

      let transpositions = 0;
      let k = 0;

      for (let i = 0; i < s1.length; i++) {
        if (matches1[i]) {
          while (!matches2[k]) k++;
          if (s1[i] !== s2[k]) transpositions++;
          k++;
        }
      }

      return (
        (matchingCharacters / s1.length + matchingCharacters / s2.length + (matchingCharacters - transpositions / 2) / matchingCharacters) / 3
      );
    };

    const jaroScore = jaroDistance(s1, s2);

    // Jaro-Winkler specific constants
    const prefixScale = 0.1;
    const maxPrefixLength = 4;

    // Calculate common prefix length
    let prefixLength = 0;
    for (let i = 0; i < Math.min(maxPrefixLength, Math.min(s1.length, s2.length)); i++) {
      if (s1[i] === s2[i]) {
        prefixLength++;
      } else {
        break;
      }
    }

    // Calculate Jaro-Winkler distance
    return jaroScore + prefixLength * prefixScale * (1 - jaroScore);
  }


  export default {
    components: { FileDragTarget, Autocomplete },
    props: [ "autocompleteData" ],
    data() {
      return {
        files: []
      }
    },
    computed: {
      artists() {
        return Object.keys(this.autocompleteData)
      },
      uploading() {
        return this.files.some(f => f.uploading)
      },
      finished() {
        return this.files.every(f => f.uploaded)
      },
      uploadButtonTitle() {
        if ( this.uploading ) return "Uploading..."
        else return "Upload Files"
      }
    },
    methods: {
      artistAutocompleteClick(index) { 
        this.$refs.autocomplete[index].core.updateResults('')

      },
      titleAutocompleteClick(index) {
        this.$refs.autocompleteTitle[index].core.updateResults('')
      },
      searchArtists(input) {
        let matched = this.artists.filter(a => a.toLowerCase().includes(input.toLowerCase()));
        return(matched);
      },
      searchSongs(file) {
        return (input) => { 
          let titles
          if ( file && file.artist && this.autocompleteData[file.artist] ) {
            titles = Object.keys(this.autocompleteData[file.artist])
          }
          if ( !titles ) {
            titles = this.artists.flatMap(a => Object.keys(this.autocompleteData[a]))
          }
          let matched = titles.filter(t => t.toLowerCase().includes(input.toLowerCase()))
          matched.sort((a, b) => a.localeCompare(b))
          return(matched)
        }
      },
      getCSRF() {
        return document.querySelector('meta[name=csrf-token]').content;
      },
      uploadProgress(file, ev) {
        file.progress = Math.round((ev.loaded * 100) / ev.total)
      },
      nameOf(index, attr) {
        return `mixes[${index}][${attr}]`
      },
      computeVersions() { 
        let cloned = JSON.parse(JSON.stringify(this.autocompleteData))

        for ( let file of this.files ) {
          if ( !cloned[file.artist] ) { 
            cloned[file.artist] = { }
          }

          if ( !cloned[file.artist][file.title] ) { 
            cloned[file.artist][file.title] = 0  
          }

          let lastVersion = parseInt(cloned[file.artist][file.title])
          file.version = cloned[file.artist][file.title] = lastVersion + 1
        }
      },
      makeFile(name) {
        return {
          name: name,
          title: name,
          titleAutoGenerated: true,
          artist: '',
          artistLinked: true,
          description: '',
          uploaded: false,
          uploading: false,
          progress: 0,
          version: 0,
          errors: {}
        }
      },
      artistFocus(ev, index) {
        if ( this.files[index].artistLinked )
          ev.target.select()
      },
      updateArtist(name, index) {
        if ( name == undefined ) return

        this.files[index].artist = name

        if ( this.files[index].titleAutoGenerated ) { 
          this.files[index].title = ''
          this.$refs.autocompleteTitle[index].setValue('')
        }

        if ( index > 0 ) {
          this.files[index].artistLinked = false
          return
        }

        for ( let i = 1; i < this.files.length; i++ ) {
          if ( this.files[i].artistLinked )
            this.files[i].artist = name
        }
        this.computeVersions();
      },
      updateTitle(name, file) {
        file.title = name
        file.titleAutoGenerated = false
        this.computeVersions();
      },
      searchForLikelyMatch(file) { 
        const artists = this.artists.filter(a => file.artist == a || file.artist == "")
        const titles = artists.flatMap(a => Object.keys(this.autocompleteData[a]).map(t => [a, t]))
        let titleToArtist = {}
        for ( const [a, t] of titles ) {
          titleToArtist[t] = a
        }

        const fs = FuzzySet(titles.map(t => t[1]))

        const matches = fs.get(file.title)
        /*
        let bestMatch = null
        let bestMatchArtist = null
        let bestMatchDistance = 0.0

        for ( const tuple of titles ) {
          const [artist, t] = tuple
          const distance = jaroWinklerDistance(file.title, t)
          if ( distance > bestMatchDistance ) { 
            bestMatch = t
            bestMatchArtist = artist
            bestMatchDistance = distance
          }
        }
        if ( bestMatchDistance > 0.7 ) {
          file.artist = bestMatchArtist
          file.title = bestMatch
        }
        */

        if ( matches.length > 0 ) { 
          file.title = matches[0][1]
          file.artist = titleToArtist[file.title]
        }
      },
      updateFiles(event) {
        this.files = []
        for ( let i = 0; i < event.target.files.length; i++ ) {
          let blob = event.target.files[i]
          let file = this.makeFile(blob.name)
          file.blob = blob
          this.$set(this.files, i, file)

          let refs = this.$refs
          let self = this
          jsmediatags.read(blob, {
            onSuccess(tag) {
              let artist =""
              if ( tag.tags.artist ) {
                artist = tag.tags.artist
              }
              file.artist = artist
              file.title = tag.tags.title

              self.searchForLikelyMatch(file)
              refs.autocomplete[i].setValue(file.artist)
              refs.autocompleteTitle[i].setValue(file.title)

              self.computeVersions();
            },
            onError(error) {
              file.artist = ""
              file.title = file.name
              self.searchForLikelyMatch(file)
              
              refs.autocomplete[i].setValue(file.artist)
              refs.autocompleteTitle[i].setValue(file.title)
            }
          })
        }
      },
      updateFilesFromDropbox(dropboxFiles) {
        this.files = dropboxFiles.map(file => {
          let f = this.makeFile(file.name)
          f.url = file.link
          f.url_filename = file.name
          return f
        })
      },
      startUpload(index) {
        if ( index == undefined ) { 
          index = 0
        }

        if ( index > this.files.length - 1 ) {
          return
        }

        let file = this.files[index]

        if ( file.uploaded ) return

        file.errors = {}
        file.uploading = true

        let data = new FormData()
        data.append("mix[artist_name]", file.artist)
        data.append("mix[song_name]", file.title)
        data.append("mix[description]", file.description)
        if ( file.blob )
          data.append("mix[file]", file.blob)

        if ( file.url )
          data.append("mix[url]", file.url)

        if ( file.url_filename )
          data.append("mix[url_filename]", file.url_filename)

        let self = this
        axios.post("/mixes", data, {
          headers: {
            'Accept': "application/json",
            'X-CSRF-Token': this.getCSRF()
          },
          onUploadProgress(e) { self.uploadProgress(file, e) }

        }).then(response => {
          file.uploaded = true
          file.uploading = false
          if ( self.finished ) {
            window.location = "/"
          } else { 
            self.startUpload(index + 1)
          }
        }).catch(error => {
          file.uploaded = false
          file.uploading = false
          console.log(error.response.data.errors)
          file.errors = error.response.data.errors
        })
      }
    },
    mounted() {
      const self = this
      let options = {
        multiselect: true,
        extensions: ['.m4a', '.mp3', '.wav', '.aiff'],
        linkType: "direct",
        success(files) {
          self.updateFilesFromDropbox(files)
        }
      }
      let button = Dropbox.createChooseButton(options);
      document.getElementById("dropbox-chooser").appendChild(button);
    }
  }
</script>
