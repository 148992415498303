<template>
   <b-modal id="add-to-playlist-modal" height="auto" :hide-header="true" :hide-footer="true" size="lg"
      body-class="add-to-playlist-modal">
      <div class="share-modal-title">
         Add track to playlist
         <img src="/images/trumpet-white.svg" id="share-trumpet" class="d-none d-sm-inline-block" />
         <button type="button" class="close share-modal-close" aria-label="Close" @click="closeModal()">
            <span aria-hidden="true">&times;</span>
         </button>
      </div>
      <div class="container-fluid pt-4 playlist-add-container">
         <div class="form-group row">
            <div class="col-sm-4">
               <label for="what" class="col-form-label">What to add:</label>
            </div>
            <div class="col-sm-8">
               <select v-model="what" class="form-control">
                  <option value="latest">Latest version of "{{ songName }}"</option>
                  <option :value="mixId">Version #{{ mixVersion }} of "{{ songName }}"</option>
               </select>
            </div>
         </div>
         <div class="form-group row">
            <div class="col-sm-4">
               <label for="playlist" class="col-form-label">Playlist:</label>
            </div>
            <div class="col-sm-8">
               <select v-model="playlist" class="form-control">
                  <option v-for="p in playlists" :value="p.id" :key="p.id">{{ p.name }}</option>
                  <option value="_new">&lt;new playlist&gt;</option>
               </select>
            </div>
         </div>
         <div v-if="playlist == '_new'" class="form-group row">
            <div class="col-sm-4">
               <label for="playlist_name" class="col-form-label">Playlist Name:</label>
            </div>
            <div class="col-sm-8">
               <input type="text" class="form-control" id="playlist_name" v-model="playlistName"
                  placeholder="playlist name...">
            </div>
         </div>
         <div class="form-group row">
            <div class="col">
               <button type="button" class="btn btn-primary" @click="save" 
               :disabled="(playlist == '_new' && !playlistName) || saving"
                  aria-label="Add Track">
                  <span v-if="saving">...</span>
                  <span v-else-if="playlist == '_new'"">
                     Create Playlist
                  </span>
                  <span v-else>
                     Add Track to Playlist
                  </span>
               </button>
            </div>
         </div>
         <div class="row">
            <div class="col pb-3">
               {{ message }}
            </div>
         </div>
      </div>
   </b-modal>
</template>

<script>
import { post_csrf } from 'utils/csrf'

function createPlaylist(name) {
   return post_csrf("/playlists.json", { "name": name })
      .then(r => {
         if (!r.ok)
            throw new Error("HTTP error " + r.status)
         return r.json()
      })
}

function addToPlaylist(playlistID, what, songId) {
   let data
   if (what == "latest") {
      data = { "object_type": "song", "object_id": songId }
   } else {
      data = { "object_type": "mix", "object_id": what }
   }

   return post_csrf(`/playlists/${playlistID}/tracks.json`, data)
}

export default {
   data() {
      return {
         playlist: null,
         playlistName: "",
         what: "latest",
         saving: false,
         message: ""
      }
   },
   props: ["mixVersion", "mixId", "songId", "songName", "playlists"],
   methods: {
      showModal(what) {
         this.playlistName = ""
         console.log(what)
         if ( what ) 
            this.what = what
         else
            this.what = "latest"
         this.saving = false
         this.message = ""

         if (!this.playlists || this.playlists.length == 0) {
            this.playlist = "_new"
         } else {
            this.playlist = this.playlists[0].id
         }
         this.$bvModal.show("add-to-playlist-modal")
      },
      closeModal() {
         this.$bvModal.hide("add-to-playlist-modal")
      },
      save(ev) {
         if (this.playlist == '_new') {
            createPlaylist(this.playlistName).then(j => {
               const playlistID = j["playlist"]["id"]
               addToPlaylist(playlistID, this.what, this.songId).then(j => window.location.pathname = `/playlists/${playlistID}`)
            })
         } else {
            addToPlaylist(this.playlist, this.what, this.songId).then(j => { 
               this.message = "Added!"
               setTimeout(this.closeModal, 800)
            })
         }

      }
   }
}
</script>

